<template>

  <div>

    <cliente-add-new
      :is-add-new-cliente-sidebar-active.sync="isAddNewClienteSidebarActive"
      :ruta-options="rutaOptions"
    />
    <!-- @refetch-data="refetchData" -->

    <pedido-add-new
      :is-add-new-pedido-sidebar-active.sync="isAddNewPedidoSidebarActive"
      :ruta-options="rutaOptions"
      :clientes-list="clientes"
    />
    <!-- :clientes-list="clientes.map(i => i.id)" -->

    <!-- Filters -->
    <!-- <users-list-filters
      :ruta-filter.sync="rutaFilter"
      :ruta-options="rutaOptions"
    /> -->
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>
          Filters <span class="text-info ml-3">{{ itemsMostrarOrden.totalBultos }} {{ itemsMostrarOrden.totalBultos !== 1 ? 'Bultos' : 'Bulto' }} en {{ itemsMostrarOrden.total }} {{ itemsMostrarOrden.total !== 1 ? 'Pedidos' : 'Pedido' }}</span>
        </h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>Zona</label>
            <v-select
              v-model="rutaFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="rutaOptions"
              class="w-100"
            />
          </b-col>

          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>Status</label>
            <v-select
              v-model="statusFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              class="w-100"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="10"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries of {{ itemsMostrarOrden.total }}</label>
          </b-col>

          <b-col
            cols="2"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="warning"
              class="btn-icon"
              @click="exportToExcel"
            >
              <feather-icon icon="DownloadIcon" />
            </b-button>
            <!-- <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              @click="calculaRutas"
            >
              <feather-icon icon="DownloadIcon" />
            </b-button> -->
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <!-- <b-button
                variant="danger"
                :disabled="false"
                @click="exportToExcel"
              >
                <span class="text-nowrap">DownloadIcon</span>
              </b-button> -->
              <b-button
                variant="primary"
                :disabled="false"
                @click="isAddNewClienteSidebarActive = true"
              >
                <span class="text-nowrap">Add User</span>
              </b-button>
              <b-button
                variant="secondary"
                class="ml-2"
                :disabled="false"
                @click="isAddNewPedidoSidebarActive = true"
              >
                <span class="text-nowrap">Add Order</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="itemsMostrarOrden.pedidos"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: Pedido -->
        <template #cell(pedido)="data">
          <b-media vertical-align="center">
            <!-- <p
              class="font-weight-bold d-block text-nowrap"
              @click="editPedido(data.item)"
            >
              {{ data.item.id }}
            </p> -->
            <b-link
              class="font-weight-bold d-block text-nowrap"
              @click="editPedido(data.item)"
            >
              {{ data.item.id }}
            </b-link>
            <small class="text-muted">{{ data.item.fechaIso }}</small>
          </b-media>
        </template>

        <!-- Column: Cliente -->
        <template #cell(client)="data">
          <b-media vertical-align="center">
            <b-link
              :to="{ name: 'apps-users-view', params: { id: data.item.cliente_id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.cliente_id }}
            </b-link>
            <small class="text-muted">{{ data.item.cliente_nombre }}</small>
          </b-media>
        </template>

        <!-- Column: ruta -->
        <template #cell(cliente_ruta)="data">
          <div class="text-nowrap">
            <span class="text-right font-weight-bolder">{{ data.item.cliente_ruta }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status).color}`"
            class="text-capitalize"
          >
            {{ resolveUserStatusVariant(data.item.status).txt }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`order-row-${data.item.id}-cart-icon`"
              icon="ShoppingCartIcon"
              class="cursor-pointer"
              :color="data.item.status > 1 ? '#ffebeb' : ''"
              size="16"
              @click="picking(data.item)"
            />
            <!-- <b-tooltip
              :title="data.item.status > 1 ? '' : 'Picking'"
              class="cursor-pointer"
              :target="`order-row-${data.item.id}-cart-icon`"
            /> -->
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item :to="{ name: 'apps-users-view', params: { id: data.item.id } }">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Details</span>
              </b-dropdown-item>

              <b-dropdown-item :to="{ name: 'apps-users-edit', params: { id: data.item.id } }">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>

              <!-- <b-dropdown-item>
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item> -->
            </b-dropdown>
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="itemsMostrarOrden.total"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <pedido-edit
      :pedido="pedidoSelect"
      :is-edit-pedido.sync="isEditPedido"
    />

    <pedido-picking
      :pedido="pedidoSelect"
      :is-picking-pedido.sync="isPickingPedido"
    />

    <mapa-basico
      :markers="itemsToMap"
    />
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BCardHeader, BCardBody,
  BDropdown, BDropdownItem, BPagination, BMedia, BLink, BBadge, // BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { paginateArray, sortCompare } from '@/libs/filter-data'
import Ripple from 'vue-ripple-directive'
import ClienteAddNew from '@/views/bimbo/components/ClienteAddNew.vue'
import PedidoAddNew from '@/views/bimbo/components/PedidoAddNew.vue'
import PedidoEdit from '@/views/bimbo/components/PedidoEdit.vue'
import PedidoPicking from '@/views/bimbo/components/PedidoPicking.vue'
import { directions } from '@/libs/google-directions'
// import MapaPicking from '@/views/bimbo/components/MapaPicking.vue'
import MapaBasico from '@/views/bimbo/components/MapaBasico.vue'
import preRutaFacke from '@/@fake-db/data/pre_ruta.json'
// import { MapElementFactory } from 'vue2-google-maps'
// import { export_json_to_excel } from '@/libs/Export2Excel'

export default {
  components: {
    ClienteAddNew,
    PedidoAddNew,
    PedidoEdit,
    PedidoPicking,

    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    // BTooltip,
    // BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
    // MapaPicking,
    MapaBasico,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isPickingPedido: false,
      isEditPedido: false,
      pedidoSelect: {},
      isAddNewClienteSidebarActive: false,
      isAddNewPedidoSidebarActive: false,
      rutaOptions: [
        { label: 'Zona 0', value: '0' },
        { label: 'Zona 1', value: '1' },
        { label: 'Zona 2', value: '2' },
      ],
      tableColumns: [
        { key: 'pedido', label: 'Pedido', sortable: true },
        { key: 'client', label: 'Cliente', sortable: true },
        // { key: 'cliente_id', label: 'ID Cliente', sortable: true },
        // { key: 'cliente_nombre', label: 'Nombre Cliente', sortable: true },
        { key: 'cliente_ruta', label: 'Zona', sortable: true },
        { key: 'bultos', label: 'Bultos', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
        { key: 'actions' },
      ],
      rutaFilter: null,
      statusFilter: { label: 'Pending', value: 0 },
      // 0 => Pending, 1 => Picked, 3 => Routed, 7 => Dispatched, 15 Delivered
      statusOptions: [
        { label: 'Pending', value: 0 },
        { label: 'Picked', value: 1 },
        { label: 'Routed', value: 3 },
        { label: 'Dispatched', value: 7 },
        { label: 'Delivered', value: 15 },
      ],
      perPage: 10,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
      searchQuery: '',
      sortBy: 'id',
      isSortDirDesc: true,
      // Export Excell
      fileName: '',
      formats: ['xlsx', 'csv', 'txt'],
      cellAutoWidth: true,
      selectedFormat: 'csv',
      // Picking Control
      pedidosPreOrdenados: [],
    }
  },

  computed: {
    clientes() {
      return this.$store.state.clientes.clientes
    },
    items() {
      return this.$store.state.pedidos.pedidos
    },
    itemsFilterPedidos() {
      const {
        rutaFilter,
        statusFilter,
        searchQuery,
        sortBy,
        isSortDirDesc,
      } = this.$data
      let pedidos = this.items
      const queryLowered = searchQuery.toLowerCase()
      pedidos = pedidos.filter(
        pedido =>
          /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
          (pedido.id.toLowerCase().includes(queryLowered) || pedido.cliente_id.toLowerCase().includes(queryLowered) || pedido.cliente_nombre.toLowerCase().includes(queryLowered) || pedido.cliente_dir_literal.toLowerCase().includes(queryLowered)) && pedido.cliente_ruta.toString() === (rutaFilter?.value || pedido.cliente_ruta.toString()) && pedido.status.toString() === (statusFilter?.value.toString() || pedido.status.toString()),
      )
      /* eslint-enable  */

      const sortedData = pedidos.sort(sortCompare(sortBy))
      if (isSortDirDesc) sortedData.reverse()
      return pedidos
    },
    itemsFiltereds() {
      const {
        rutaFilter,
        statusFilter,
        perPage,
        currentPage,
        searchQuery,
        sortBy,
        isSortDirDesc,
      } = this.$data
      let pedidos = this.items
      const queryLowered = searchQuery.toLowerCase()
      pedidos = pedidos.filter(
        pedido =>
          /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
          (pedido.id.toLowerCase().includes(queryLowered) || pedido.cliente_id.toLowerCase().includes(queryLowered) || pedido.cliente_nombre.toLowerCase().includes(queryLowered) || pedido.cliente_dir_literal.toLowerCase().includes(queryLowered)) && pedido.cliente_ruta.toString() === (rutaFilter?.value || pedido.cliente_ruta.toString()) && pedido.status.toString() === (statusFilter?.value.toString() || pedido.status.toString()),
      )
      /* eslint-enable  */

      const sortedData = pedidos.sort(sortCompare(sortBy))
      if (isSortDirDesc) sortedData.reverse()
      const reducer = (accumulator, currentValue) => accumulator + currentValue
      if (!pedidos.length) {
        return {
          pedidos: [],
          total: 0,
          totalBultos: 0,
        }
      }
      const pedidosResult = pedidos.map(pedido => {
        const ped = pedido
        ped.bultos = ped.productos.map(p => p.uds).reduce(reducer)
        ped.fechaIso = this.fechaHoraCortas(ped.fecha)
        return ped
      })
      return {
        pedidos: paginateArray(pedidosResult, perPage, currentPage),
        total: pedidosResult.length,
        totalBultos: pedidosResult.map(p => p.bultos).reduce(reducer),
      }
    },
    itemsMostrarOrden() {
      const {
        perPage,
        currentPage,
      } = this.$data
      if (this.pedidosPreOrdenados.length) {
        return {
          pedidos: paginateArray(this.pedidosPreOrdenados, perPage, currentPage),
          total: this.pedidosPreOrdenados.length,
          totalBultos: this.itemsFiltereds.totalBultos,
        }
      }
      return this.itemsFiltereds
    },
    itemsToMap() {
      const data = this.itemsFiltereds?.pedidos
      if (!data.length) return []
      const result = data.map(p => ({
        id: p.cliente_id,
        lat: p.cliente_lat,
        lng: p.cliente_lng,
        nombre: p.cliente_nombre,
        ruta: p.cliente_ruta,
      }))

      return result
    },
    // totalBultos() {
    //   const reducer = (accumulator, currentValue) => accumulator + currentValue
    //   return this.itemsFiltereds.pedidos.map(p => p.bultos).reduce(reducer)
    // },
    // clientes() {
    //   const consulta = {
    //     ruta: this.rutaFilter, perPage: this.perPage, currentPage: this.currentPage, q: this.searchQuery, sortBy: this.sortBy, sortDesc: this.isSortDirDesc,
    //   }
    //   return this.$store.getters['clientes/clientes'](consulta)
    // },
  },

  created() {
    this.fetchPedidos()
    this.fetchClientes()
  },

  // async mounted() {
  //   try {
  //     const google = await gmapsInit()
  //     const directionsService = new google.maps.DirectionsService()

  //     const start = 'Carrer de Pere IV, 520, Barcelona'
  //     const end = 'Carrer de Pere IV, 520, Barcelona'
  //     const idClients = ['12345', 'ABC1232', '987AC', '112233', '12346', '12347', '12348', '12349', '12340', '12341']
  //     const waypts = [{
  //       location: 'Carrer de Llull, 70, 08005 Barcelona, Spain',
  //       stopover: true,
  //     }, {
  //       location: 'Carrer de Bac de Roda, 168, 08020 Barcelona',
  //       stopover: true,
  //     }, {
  //       location: 'C. de Bolívia, 11, 08018 Barcelona, Spain',
  //       stopover: true,
  //     }, {
  //       location: 'Carrer de Ferrer Bassa, 4, Barcelona',
  //       stopover: true,
  //     }, {
  //       location: 'Carrer de Bilbao, 68, 08005 Barcelona, Spain',
  //       stopover: true,
  //     }, {
  //       location: 'C/ del Perú, 58, 08018 Barcelona, Spain',
  //       stopover: true,
  //     }, {
  //       location: 'Carrer de Sant Fructuós, 45, 08004 Barcelona, Spain',
  //       stopover: true,
  //     }, {
  //       location: 'Carrer de Pujades, 175, 08005 Barcelona, Spain',
  //       stopover: true,
  //     }, {
  //       location: 'Carrer de Cartagena, 293, 08025 Barcelona, Spain',
  //       stopover: true,
  //     }, {
  //       location: 'Carrer del Dr. Trueta, 138, 08005 Barcelona, Spain',
  //       stopover: true,
  //     }]

  //     directionsService.route(
  //       {
  //         origin: start, // document.getElementById("start").value,
  //         destination: end, // document.getElementById("end").value,
  //         waypoints: waypts,
  //         optimizeWaypoints: true,
  //         travelMode: google.maps.TravelMode.BICYCLING,
  //       },
  //       (response, status) => {
  //         if (status === 'OK' && response) {
  //           const route = response.routes[0]
  //           console.log(route.waypoint_order)
  //           const order = route.waypoint_order
  //           const result = []
  //           for (let a = 0; a < idClients.length; a += 1) {
  //             result.push({
  //               cliente_id: idClients[a],
  //               direccion: waypts[a].location,
  //               order: order[a],
  //             })
  //           }
  //           console.log(result)
  //         } else {
  //           console.log('Algo falló')
  //         }
  //       },
  //     )
  //   } catch (error) {
  //     console.error(error)
  //   }
  // },
  beforeDestroy() {
    // this.$store.dispatch('pedidos/UNSUSCRIBE_PEDIDOS')
    // this.$store.dispatch('clientes/UNSUSCRIBE_CLIENTES')
  },

  methods: {
    picking(item) {
      if (!item || item.status > 1) return
      this.pedidoSelect = item
      this.isPickingPedido = true
    },
    editPedido(p) {
      this.pedidoSelect = p
      this.isEditPedido = true
    },
    fetchPedidos() {
      this.$store.dispatch('pedidos/getPedidos')
    },
    fetchClientes() {
      this.$store.dispatch('clientes/getClientes')
    },
    sumaBultos(accumulator, currentValue) {
      return accumulator + currentValue
    },

    fechaHoraCortas(f) {
      const fecha = new Date(f) || new Date()
      return new Intl.DateTimeFormat('es-ES', { dateStyle: 'short', timeStyle: 'short' }).format(fecha)
    },

    resolveUserStatusVariant(status) {
      // 0 => Pending, 1 => Picked, 3 => Routed, 7 => Dispatched, 15 Delivered
      const result = {
        txt: 'Unknown',
        color: 'danger',
      }
      switch (status) {
        case 0:
          result.txt = 'Pending'
          result.color = 'secondary'
          break
        case 1:
          result.txt = 'Picked'
          result.color = 'primary'
          break
        case 3:
          result.txt = 'Routed'
          result.color = 'warning'
          break
        case 7:
          result.txt = 'Dispatched'
          result.color = 'info'
          break
        case 15:
          result.txt = 'Delivered'
          result.color = 'success'
          break
        default:
          break
      }
      return result
    },

    refetchData() {
      // console.log('vale, entendido...')
    },
    exportToExcel() {
      // console.log('Exportando...')
      const list = this.itemsFilterPedidos
      // console.log(list.length)
      const result = []
      // list.forEach(item => {
      for (let index = 0; index < list.length; index += 1) {
        const item = list[index]
        const { productos } = item
        const newItem = JSON.parse(JSON.stringify(item))
        delete newItem.productos
        // console.log('**** Pedido: ', item.id)
        // let line = 0
        // productos.forEach(p => {
        for (let index2 = 0; index2 < productos.length; index2 += 1) {
          const p = productos[index2]
          // line += 1
          newItem.id_line = index2 + 1
          newItem.ref = p.ref
          newItem.uds = p.uds
          result.push(newItem)
          // console.log(`${result.length} - ${newItem.ref} => ${newItem.uds}`)
        }
      }
      // console.log(JSON.stringify(result))
      const headers = Object.keys(result[0])
      // console.log(headers)
      // if (list) return
      import('@/libs/Export2Excel').then(excel => {
        // const list = []
        // this.selected.forEach(item => {
        //   list.push(this.extraeInfo(item))
        // })
        // const list = this.selected
        // const data = this.formatJson(this.headerVal, list)
        const data = this.formatJson(headers, result)
        // const data = result
        excel.export_json_to_excel({
          header: headers,
          data,
          filename: 'export',
          autoWidth: false,
          bookType: 'csv',
        })
        // this.clearFields()
      })
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },
    async calculaRutas() {
      console.log('calculando')
      const datos = {
        start: 'Carrer de Pere IV, 520, Barcelona',
        end: 'Carrer de Pere IV, 520, Barcelona',
        waypts: [
          {
            location: 'Carrer de Llull, 70, 08005 Barcelona, Spain',
            stopover: true,
          }, {
            location: 'Carrer de Bac de Roda, 168, 08020 Barcelona',
            stopover: true,
          }, {
            location: 'C. de Bolívia, 11, 08018 Barcelona, Spain',
            stopover: true,
          }, {
            location: 'Carrer de Ferrer Bassa, 4, Barcelona',
            stopover: true,
          }, {
            location: 'Carrer de Bilbao, 68, 08005 Barcelona, Spain',
            stopover: true,
          }, {
            location: 'C/ del Perú, 58, 08018 Barcelona, Spain',
            stopover: true,
          }, {
            location: 'Carrer de Sant Fructuós, 45, 08004 Barcelona, Spain',
            stopover: true,
          }, {
            location: 'Carrer de Pujades, 175, 08005 Barcelona, Spain',
            stopover: true,
          }, {
            location: 'Carrer de Cartagena, 293, 08025 Barcelona, Spain',
            stopover: true,
          }, {
            location: 'Carrer del Dr. Trueta, 138, 08005 Barcelona, Spain',
            stopover: true,
          },
        ],
      }
      const result = await directions(datos)
      console.log(JSON.stringify(result))
      // .then(result => {
      // })
    },
    async preCalculaRutas() {
      console.log('pre-calculando')
      // const pedidosIds = this.itemsFilterPedidos.map(p => p.id)
      const waypts = this.itemsFilterPedidos.map(p => ({
        location: p.cliente_dir_literal,
        stopover: true,
      }))
      const datos = {
        start: 'Carrer de Pere IV, 520, Barcelona',
        end: 'Carrer de Pere IV, 520, Barcelona',
        waypts,
      }
      // console.log(datos)
      const result = await directions(datos)
      console.log(JSON.stringify(result))
      // .then(result => {
      // })
    },
    tratarPreruta() {
      const pedidos = this.itemsFilterPedidos
      const orden = preRutaFacke.waypoint_order
      console.log(orden)
      const result = []
      let count = 0
      orden.forEach(index => {
        const pedido = pedidos[index]
        pedido.orden = count
        result.push(pedido)
        count += 1
      })
      this.pedidosPreOrdenados = result
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
