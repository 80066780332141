<template>
  <b-modal
    id="editaPedido"
    ref="edit-pedido"
    :visible="isEditPedido"
    :title="`Edición de Pedido ${pedido.id}`"
    ok-title="Guardar"
    cancel-variant="outline-secondary"
    centered
    size="lg"
    @change="(val) => $emit('update:is-edit-pedido', val)"
    @ok="saveChanges"
  >
    <b-form
      v-if="pedidoEdit"
      @submit.prevent
    >
      <b-tabs
        v-if="pedidoEdit"
        pills
      >
        <!-- Operativa -->
        <b-tab active>
          <template #title>
            <feather-icon
              icon="InfoIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Estado</span>
          </template>

          <b-row>
            <h5 class="ml-2 py-2 text-centre text-info">
              Este pedido se encuentra en <strong :class="`text-${resolveStatusVariant(pedido.status).colorOrigin}`">{{ resolveStatusVariant(pedido.status).txt }}.</strong>{{ isChangedStatus }}
            </h5>
          </b-row>
          <b-row class="my-2">
            <b-col cols="12 ml-2 my-2">
              <b-button-group>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  :variant="resolveStatusVariant(-1).color"
                >
                  {{ resolveStatusVariant(-1).txt }}
                </b-button>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  :variant="resolveStatusVariant(0).color"
                  @click.stop="selectNewStatus(0)"
                >
                  {{ resolveStatusVariant(0).txt }}
                </b-button>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  :variant="resolveStatusVariant(1).color"
                  @click.stop="selectNewStatus(1)"
                >
                  {{ resolveStatusVariant(1).txt }}
                </b-button>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  :variant="resolveStatusVariant(3).color"
                  @click.stop="selectNewStatus(3)"
                >
                  {{ resolveStatusVariant(3).txt }}
                </b-button>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  :variant="resolveStatusVariant(7).color"
                  @click.stop="selectNewStatus(7)"
                >
                  {{ resolveStatusVariant(7).txt }}
                </b-button>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  :variant="resolveStatusVariant(15).color"
                  @click.stop="selectNewStatus(15)"
                >
                  {{ resolveStatusVariant(15).txt }}
                </b-button>
              </b-button-group>
            </b-col>
          </b-row>

          <!-- Notas -->
          <b-row class="my-2">
            <b-col cols="6">
              <label for="textarea-default">Notas Admin</label>
              <b-form-textarea
                id="notas-admin"
                v-model="pedidoEdit.notas_admin"
                placeholder="Notas Admin"
                rows="3"
              />
            </b-col>
            <b-col cols="6">
              <label for="textarea-default">Notas Operativas</label>
              <b-form-textarea
                id="notas-operativas"
                v-model="pedidoEdit.notas_operativa"
                placeholder="Notas Operativas"
                rows="3"
              />
            </b-col>
          </b-row>
          <!-- Incidencia + Descarte -->
          <b-row class="my-2">
            <b-col cols="6">
              <b-form-group>
                <b-form-checkbox
                  v-model="pedidoEdit.is_incidencia"
                  class="custom-control-warning"
                  name="check-button"
                  switch
                  inline
                >
                  Pedido con incidencia/s
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group>
                <b-form-checkbox
                  v-model="pedidoEdit.is_descartado"
                  class="custom-control-danger"
                  name="check-button"
                  switch
                  inline
                >
                  Descartar Pedido
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </b-tab>

        <!-- Cliente -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="UserIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Cliente</span>
          </template>
          <b-row>
            <b-col md="2">
              <b-form-group
                label="Cliente ID"
                label-for="mc-first-name"
              >
                <b-form-input
                  id="mc-cliente-id"
                  v-model="pedidoEdit.cliente_id"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Cliente NOMBRE"
                label-for="mc-first-name"
              >
                <b-form-input
                  id="mc-cliente-nombre"
                  v-model="pedidoEdit.cliente_nombre"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="Latitud"
                label-for="mc-cliente-lat"
              >
                <b-form-input
                  id="mc-cliente-lat"
                  v-model="pedidoEdit.cliente_lat"
                  placeholder="Latitud"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="Longitud"
                label-for="mc-cliente-lng"
              >
                <b-form-input
                  id="mc-cliente-lng"
                  v-model="pedidoEdit.cliente_lng"
                  placeholder="Longitud"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Dirección completa"
                label-for="mc-cliente-direccion"
              >
                <b-form-input
                  id="mc-cliente-direccion"
                  v-model="pedidoEdit.cliente_dir_literal"
                  placeholder="Dirección completa"
                />
              </b-form-group>
            </b-col>
            <b-col md="8">
              <b-form-group
                label="Calle"
                label-for="mc-cliente-calle"
              >
                <b-form-input
                  id="mc-cliente-calle"
                  v-model="pedidoEdit.cliente_dir_calle"
                  placeholder="Calle"
                />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                label="Número"
                label-for="mc-cliente-numero"
              >
                <b-form-input
                  id="mc-cliente-numero"
                  v-model="pedidoEdit.cliente_dir_numero"
                  placeholder="Num."
                />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                label="CP"
                label-for="mc-cliente-cp"
              >
                <b-form-input
                  id="mc-cliente-cp"
                  v-model="pedidoEdit.cliente_dir_cp"
                  placeholder="CP"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Localidad"
                label-for="mc-cliente-localidad"
              >
                <b-form-input
                  id="mc-cliente-localidad"
                  v-model="pedidoEdit.cliente_dir_localidad"
                  placeholder="Localidad"
                />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                label="HUB"
                label-for="mc-cliente-hub"
              >
                <b-form-input
                  id="mc-cliente-hub"
                  v-model="pedidoEdit.cliente_hub"
                  placeholder="HUB"
                />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                label="Ruta"
                label-for="mc-cliente-ruta"
              >
                <b-form-input
                  id="mc-cliente-ruta"
                  v-model="pedidoEdit.cliente_ruta"
                  placeholder="Ruta"
                />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                label="Hora Ini"
                label-for="mc-cliente-hora-ini"
              >
                <b-form-timepicker
                  id="mc-cliente-hora-ini"
                  v-model="hIni"
                  v-bind="labels[locale] || {}"
                  :locale="locale"
                  @context="onContextIni"
                />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                label="Hora Fin"
                label-for="mc-cliente-hora-fin"
              >
                <b-form-timepicker
                  id="mc-cliente-hora-fin"
                  v-model="hFin"
                  v-bind="labels[locale] || {}"
                  :locale="locale"
                  @context="onContextFin"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-tab>

        <!-- Productos -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="InfoIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Productos</span>
          </template>
          <b-row v-if="pedidoEdit.status !== 0">
            <b-col md="12">
              <h5 class="ml-2 py-2 text-centre text-info">
                Los productos de este pedido no pueden modificarse por encontrarse ya realizado el picking.
              </h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <productos-list
                v-if="pedidoEdit"
                :productos="pedidoEdit.productos"
                :is-disabled="pedidoEdit.status !== 0"
                @suma="sumarProductos"
              />
            </b-col>
            <!-- <b-col md="6">
              <b-form
                class="p-2"
                @submit.prevent="addProduct"
              >
                <b-form-group
                  label="Ref."
                  label-for="ep-ref"
                >
                  <b-form-input
                    id="ep-ref"
                    v-model="newProduct.ref"
                  />
                </b-form-group>
                <b-form-group
                  label="Unidades"
                  label-for="ep-uds"
                >
                  <b-form-input
                    id="ep-uds"
                    v-model="newProduct.uds"
                    type="number"
                    :disabled="!newProduct.ref"
                  />
                </b-form-group>
                <b-button
                  variant="warning"
                  :disabled="false"
                  @click="addProduct"
                >
                  <span class="text-nowrap">Add Product</span>
                </b-button>
              </b-form>
            </b-col> -->
          </b-row>
        </b-tab>
      </b-tabs>
    </b-form>
  </b-modal>
</template>

<script>
import {
  BModal, VBModal, BForm, BFormInput, BFormGroup, BButtonGroup,
  BRow, BCol, BFormCheckbox, BButton, BFormTimepicker, BTab, BTabs,
  BFormTextarea,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ProductosList from './ProductosList.vue'

export default {
  components: {
    // BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BFormCheckbox,
    BButton,
    BFormTimepicker,
    BTab,
    BTabs,
    BButtonGroup,
    BFormTextarea,
    // vSelect,
    ProductosList,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  model: {
    prop: 'isEditPedido',
    event: 'update:is-edit-pedido',
  },
  props: {
    pedido: {
      type: Object,
      required: true,
    },
    isEditPedido: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      newProduct: {
        ref: '',
        uds: 1,
      },
      hIni: null,
      hFin: null,
      pedidoEdit: null,
      contextIni: null,
      contextFin: null,
      locale: 'es-ES',
      labels: {
        es: {
          labelHours: 'Hora',
          labelMinutes: 'Minuto',
          labelSeconds: 'Segundos',
          labelIncrement: 'Más',
          labelDecrement: 'Menos',
          labelSelected: 'Seleeciona',
          labelNoTimeSelected: 'Nada seleccionado',
          labelCloseButton: 'Cerrar',
        },
      },
    }
  },
  computed: {
    isChangedStatus() {
      const actual = this.pedido?.status || false
      const nuevo = this.pedidoEdit?.status || false
      let result = ''
      if (actual !== nuevo) {
        result = ` Ahora va a cambiar a ${this.resolveStatusVariant(nuevo).txt}`
      }
      return result
    },
  },
  watch: {
    pedido() {
      this.initialize()
    },
  },
  methods: {
    sumarProductos(e) {
      // this.pedidoEdit.productos[e.index].uds += e.valor
      this.pedidoEdit.productos = e
    },
    onContextIni(ctx) {
      // this.contextIni = ctx
      this.pedidoEdit.cliente_hora_ini = ctx.hours * 3600 + ctx.minutes * 60
    },
    onContextFin(ctx) {
      // this.context = ctx
      this.pedidoEdit.cliente_hora_fin = ctx.hours * 3600 + ctx.minutes * 60
    },
    initialize() {
      this.pedidoEdit = JSON.parse(JSON.stringify(this.pedido))
      this.hIni = new Date(this.pedidoEdit.cliente_hora_ini * 1000).toISOString().substr(11, 8)
      this.hFin = new Date(this.pedidoEdit.cliente_hora_fin * 1000).toISOString().substr(11, 8)
    },
    resolveStatusVariant(compare) {
      const { status } = this.pedidoEdit
      const result = {
        txt: '',
        color: '',
      }
      const posibles = [0, 1, 3, 7, 15]
      const isValidStatus = posibles.includes(parseInt(status, 10))
      switch (compare) {
        case 0:
          result.txt = 'Pending'
          result.color = 'secondary'
          break
        case 1:
          result.txt = 'Picked'
          result.color = 'primary'
          break
        case 3:
          result.txt = 'Routed'
          result.color = 'warning'
          break
        case 7:
          result.txt = 'Dispatched'
          result.color = 'info'
          break
        case 15:
          result.txt = 'Delivered'
          result.color = 'success'
          break
        default:
          result.txt = 'Unknown'
          result.color = 'danger'
          break
      }
      result.colorOrigin = result.color
      if (compare !== status && isValidStatus) {
        result.color = `outlined-${result.color}`
      }
      return result
    },
    selectNewStatus(newStatus) {
      this.pedidoEdit.status = newStatus
    },
    addProduct() {
      console.log('agregando...')
      console.log(this.newProduct)
      this.pedidoEdit.productos.push(this.newProduct)
      this.newProduct = {
        ref: '',
        uds: 1,
      }
    },
    saveChanges() {
      // edit-pedido
      // bvModalEvt.preventDefault()
      this.$store.dispatch('pedidos/updatePedido', this.pedidoEdit)
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
