import { ref, computed } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
// import store from '@/store'

export default function usePedidos() {
  const blankProducto = {
    ref: '',
    uds: 0,
  }
  const blankPedidoData = {
    cliente: '',
    productos: [],
  }

  const sanitizeProduct = product => ({
    ref: product.ref,
    uds: parseInt(product.uds, 10),
  })

  const productoData = ref(JSON.parse(JSON.stringify(blankProducto)))
  const pedidoData = ref(JSON.parse(JSON.stringify(blankPedidoData)))
  const resetpedidoData = () => {
    pedidoData.value = JSON.parse(JSON.stringify(blankPedidoData))
  }
  const totalBultos = ref(0)
  const calcBultos = () => {
    if (!pedidoData.value.productos.length) return 0
    const reducer = (accumulator, currentValue) => accumulator + currentValue
    return pedidoData.value.productos.map(p => p.uds).reduce(reducer)
  }

  const resetproductoData = () => {
    if (productoData.value.ref && productoData.value.uds > 0 && productoData.value.uds < 1000) {
      pedidoData.value.productos.push(sanitizeProduct(productoData.value))
      productoData.value = JSON.parse(JSON.stringify(blankProducto))
      totalBultos.value = calcBultos()
    }
  }

  const deleteproductoData = index => {
    pedidoData.value.productos.splice(index, 1)
    totalBultos.value = calcBultos()
  }

  const isPermitSave = computed(() => typeof pedidoData.value.cliente === 'object' && pedidoData.value.cliente.id && pedidoData.value.productos.length)

  const onSubmit = () => {
    //
  }

  const {
    refFormObserver,
    getValidationState,
    resetForm,
  } = formValidation(resetpedidoData)

  return {
    pedidoData,
    resetproductoData,
    totalBultos,
    productoData,
    onSubmit,

    refFormObserver,
    getValidationState,
    resetForm,
    deleteproductoData,
    isPermitSave,
  }
}
