<template>
  <b-modal
    id="pickingPedido"
    ref="picking-pedido"
    :visible="isPickingPedido"
    :title="`Picking Pedido ${pedido.id} | ${pedido.cliente_nombre} | ${allProducts} Bultos`"
    ok-title="Guardar"
    cancel-variant="outline-secondary"
    hide-footer
    centered
    size="lg"
    @change="(val) => $emit('update:is-picking-pedido', val)"
    @ok="saveChanges"
  >
    <form-wizard
      v-if="pedidoEdit"
      color="#60b34c"
      :title="null"
      :subtitle="null"
      layout="vertical"
      finish-button-text="Imprimir"
      next-button-text="Siguiente"
      back-button-text="Anterior"
      class="wizard-vertical mb-3"
      @on-complete="formSubmitted"
    >

      <tab-content
        v-for="(item, i) in pedidoEdit.productos"
        :key="i"
        :title="item.ref"
      >
        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <h5 class="mb-0">
              {{ item.ref }}
            </h5>
            <small class="text-muted">
              {{ productoNombre(item.ref) }}
            </small>
          </b-col>
        </b-row>

        <b-row
          v-for="(line, n) in item.lines"
          :key="n"
        >
          <b-col md="4">
            <b-form-group
              label="F. Caducidad"
              :label-for="`fecha-${item.ref}-${n}`"
            >
              <b-form-input
                :id="`fecha-${item.ref}-${n}`"
                v-model="pedidoEdit.productos[i].lines[n].fecha"
                type="text"
                placeholder="Fecha"
                @input="compruebaCheck(item.ref, i, n)"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Lote"
              :label-for="`lote-${item.ref}-${n}`"
            >
              <b-form-input
                :id="`lote-${item.ref}-${n}`"
                v-model="pedidoEdit.productos[i].lines[n].lote"
                type="text"
                placeholder="Lote"
                @input="compruebaCheck(item.ref, i, n)"
              />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-checkbox
              v-model="productsChecked"
              :value="`${item.ref}-${i}-${n}`"
              @change="dismissCheck(item.ref, i, n)"
            >
              Ok
            </b-form-checkbox>
          </b-col>
          <!-- <b-col md="2">
            <b-form-checkbox
              v-model="pedidoEdit.productos[i].lines[n].added"
              :value="1"
              :unchecked-value="0"
            >
              Ok
            </b-form-checkbox>
          </b-col> -->
        </b-row>
      </tab-content>
    </form-wizard>
    <!-- <pre>{{ productsChecked }}</pre>
    <pre>{{ productsAllRef }}</pre> -->
  </b-modal>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import {
  BModal, VBModal, BFormInput, BFormGroup,
  BRow, BCol, BFormCheckbox,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
// import BaseCheck from './PedidoPickingCheck.vue'

export default {
  components: {
    FormWizard,
    TabContent,
    // BButton,
    BModal,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BFormCheckbox,
    // BaseCheck,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  model: {
    prop: 'isPickingPedido',
    event: 'update:is-picking-pedido',
  },
  props: {
    pedido: {
      type: Object,
      required: true,
    },
    isPickingPedido: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      selected: false,
      pedidoEdit: null,
      productosEdit: null,
      productsChecked: [],
      productsAllRef: [],
    }
  },
  computed: {
    productosDb() {
      return this.$store.state.productos.productos
    },
    allProducts() {
      return this.productsAllRef.length
    },
    totalProductosPickeds() {
      const lines = this.pedidoEdit.productos.map(p => p.lines)
      const suma = lines.map(f => f.map(l => l.added).reduce((a, b) => a + b)).reduce((a, b) => a + b)
      return suma
    },
    // lineas() {
    //   if (!this.pedidoEdit || !this.pedidoEdit.productos.length) return 0
    //   return this.pedidoEdit.productos.map(p => p.lines)
    // },
    // sumaProductos() {
    //   if (!this.pedidoEdit || !this.pedidoEdit?.productos?.length) return 0
    //   const ln = this.pedidoEdit.productos
    //   if (!ln.length) return 0
    //   const lines = ln.map(p => p.lines)
    //   const linesMapeo = lines.map(f => f.map(l => l.added))
    //   return linesMapeo ? linesMapeo.reduce((a, b) => a + b) : 0
    // },
  },
  watch: {
    pedido() {
      this.initialize()
      this.fetchProductos()
    },
    // productsChecked(newValue, oldValue) {
    //   if (newValue.length > oldValue.length) return
    //   this.dismissProductsChecked()
    // },
  },
  methods: {
    initialize() {
      this.pedidoEdit = JSON.parse(JSON.stringify(this.pedido))
      const { productos } = this.pedidoEdit
      this.productsChecked = []
      this.productsAllRef = []
      let bultosCalculados = 0
      for (let index = 0; index < productos.length; index += 1) {
        const producto = JSON.parse(JSON.stringify(productos[index]))
        bultosCalculados += producto.uds
        if (!productos[index].lines) productos[index].lines = []
        for (let ud = 0; ud < producto.uds; ud += 1) {
          const id = `${producto.ref}-${index}-${ud}`
          this.productsAllRef.push(id)
          // Chequea los productos marcados/agregados para el control del checkbox
          if (productos[index].lines && productos[index].lines[ud]) {
            const { added } = productos[index].lines[ud]
            if (added) this.productsChecked.push(id)
          } else {
            productos[index].lines[ud] = {
              id,
              fecha: '',
              lote: '',
              added: 0,
            }
          }
        }
      }
      // this.pedidoEdit.productos = productos
      this.productosEdit = productos
      this.pedidoEdit.bultosCalculados = bultosCalculados
    },
    initializeOld() {
      this.pedidoEdit = JSON.parse(JSON.stringify(this.pedido))
      const { productos } = this.pedidoEdit
      let bultosCalculados = 0
      for (let index = 0; index < productos.length; index += 1) {
        const producto = productos[index]
        bultosCalculados += producto.uds
        if (!producto.lines) {
          producto.lines = []
          for (let ud = 0; ud < producto.uds; ud += 1) {
            producto.lines.push({
              id: `${producto.ref}-${index}-${ud}`,
              fecha: '',
              lote: '',
              added: 0,
            })
            productos[index] = producto
          }
        }
      }
      // this.pedidoEdit.productos = productos
      this.productosEdit = productos
      this.pedidoEdit.bultosCalculados = bultosCalculados
    },
    fetchProductos() {
      this.$store.dispatch('productos/getProductos')
    },
    productoNombre(ref) {
      if (!this.productosDb.length) return 'Producto no encontrado'
      return this.productosDb.filter(p => p.id === ref).map(p => p.name)[0] || 'Producto no encontrado'
    },
    compruebaCheckOld(i, n) {
      if (this.productosEdit[i].lines[n].added) return
      console.log(`comprobando productosEdit[${i}].lines[${n}]`)
      const line = this.productosEdit[i].lines[n]
      console.log(line)
      if (line.fecha || line.lote) {
        this.productosEdit[i].lines[n].added = 1
        this.$nextTick()
          .then(() => {
            console.log('updated')
            // console.log(this.$refs[`linea-${i}-${n}`][0])
            // const el = this.$refs[`linea-${i}-${n}`][0]
            // this.$emit('input', this.$refs[`linea-${i}-${n}`][0])
            // this.$refs[`linea-${i}-${n}`][0].checked = true
            // this.$refs[`linea-${i}-${n}`][0].input()
          })
      }
    },
    compruebaCheck(ref, i, n) {
      // console.log(`comprobando pedidoEdit.productos[${i}].lines[${n}]`)
      const id = `${ref}-${i}-${n}`
      if (this.productsChecked.includes(id)) return
      const line = this.pedidoEdit.productos[i].lines[n]
      // console.log(line)
      if (line.fecha || line.lote) {
        this.productsChecked.push(id)
        this.pedidoEdit.productos[i].lines[n].added = 1
      }
    },
    dismissCheck(ref, i, n) {
      // Al hacer click en el checkbox
      const id = `${ref}-${i}-${n}`
      // console.log('clicado: ', id)
      // console.log(JSON.stringify(this.productsChecked))
      if (this.productsChecked.includes(id)) {
        // Agregado
        console.log('ON')
        this.pedidoEdit.productos[i].lines[n].added = 1
        return
      }
      // Se ha eliminado el producto
      console.log('OFF => eliminar fecha y lote')
      this.pedidoEdit.productos[i].lines[n].added = 0
    },
    saveChanges() {
      if (!this.pedidoEdit || !this.pedidoEdit.productos.length) return
      // const lines = this.pedidoEdit.productos.map(p => p.lines)
      const suma = this.totalProductosPickeds // lines.map(f => f.map(l => l.added).reduce((a, b) => a + b)).reduce((a, b) => a + b)
      console.log(suma)
      if (!suma) return
      this.pedidoEdit.bultosPicked = suma
      // this.isPickingCompleted = suma === this.pedidoEdit.bultosCalculados
      this.pedidoEdit.status = 1
      const datos = {
        id: this.pedidoEdit.id,
        productos: this.pedidoEdit.productos,
        is_picking_completed: suma === this.allProducts,
        status: 1,
      }
      this.$store.dispatch('pedidos/updatePedido', datos)
      this.$emit('update:is-picking-pedido', false)
      this.$router.push({ name: 'bimbo-pedidos-ticket', params: { id: this.pedido.id } })
    },
    formSubmitted() {
      this.saveChanges()
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
