<template>
  <b-sidebar
    id="add-new-pedido-sidebar"
    :visible="isAddNewPedidoSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-pedido-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Order
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Cliente -->
          <validation-provider
            #default="validationContext"
            name="Cliente"
            rules="required"
          >
            <b-form-group
              label="Cliente"
              label-for="cliente"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="pedidoData.cliente"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="id"
                :options="clientesList"
                :clearable="false"
                input-id="cliente"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <template v-if="pedidoData.cliente">
            <b-media vertical-align="center">
              <b-media-body class="mb-2 text-info">
                <div class="mail-details txt-recortar">
                  <div class="mail-items">
                    <small><span class="text-truncate">{{ pedidoData.cliente.nombre }}</span></small>
                  </div>
                </div>

                <div class="mail-message txt-recortar">
                  <p class="text-truncate mb-0">
                    <small>{{ pedidoData.cliente.dir_literal }}</small>
                  </p>
                  <p class="text-truncate mb-0">
                    <small>Ruta: <strong>{{ pedidoData.cliente.ruta }}</strong> | HUB: <strong>{{ pedidoData.cliente.hub }}</strong></small>
                  </p>
                </div>
              </b-media-body>
            </b-media>
          </template>

          <hr>

          <h5>Productos:</h5>

          <!-- Productos -->
          <b-row>
            <b-col md="8">
              <validation-provider
                #default="validationContext"
                name="ref"
                rules=""
              >
                <b-form-group
                  label="Ref."
                  label-for="ref"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-input
                    id="ref"
                    v-model="productoData.ref"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="4">
              <validation-provider
                #default="validationContext"
                name="uds"
                rules=""
              >
                <b-form-group
                  label="Unidades"
                  label-for="uds"
                >
                  <b-form-input
                    id="uds"
                    v-model="productoData.uds"
                    type="number"
                    :disabled="!productoData.ref"
                    :state="getValidationState(validationContext)"
                    @change="resetproductoData"
                  />

                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <template v-if="pedidoData.productos.length">
            <b-row
              v-for="(item, i) in pedidoData.productos"
              :key="i"
              class="mb-1"
            >
              <b-col cols="4">
                {{ item.ref }}
              </b-col>
              <b-col cols="4">
                {{ item.uds }}
              </b-col>
              <b-col cols="2">
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="flat-danger"
                  class="btn-icon"
                  @click.stop="deleteproductoData(i)"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </b-col>
            </b-row>

            <b-row class="mt-3 text-right mr-2">
              <b-col cols="12">
                <strong>Total Bultos: {{ totalBultos }}</strong>
              </b-col>
            </b-row>
            <!-- <b-media vertical-align="center">
              <b-media-body class="mb-2 text-info">
                <div class="mail-details txt-recortar">
                  <div
                    v-for="(item, i) in pedidoData.productos"
                    :key="i"
                    class="mail-items"
                  >
                    <p>
                      <feather-icon
                        icon="TrashIcon"
                        size="24"
                      />
                      <small>{{ item.ref }}</small>
                      <small>{{ item.uds }}</small>
                    </p>
                  </div>
                </div>
              </b-media-body>
            </b-media> -->
          </template>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              :disabled="!isPermitSave"
              @click="onSave"
            >
              Save
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BMedia, BMediaBody, BRow, BCol,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import { ref } from '@vue/composition-api'
import { required, alphaNum } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import usePedidos from './usePedidos'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BMedia,
    BMediaBody,
    BRow,
    BCol,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewPedidoSidebarActive',
    event: 'update:is-add-new-pedido-sidebar-active',
  },
  props: {
    isAddNewPedidoSidebarActive: {
      type: Boolean,
      required: true,
    },
    rutaOptions: {
      type: Array,
      required: true,
    },
    clientesList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
    }
  },
  setup(props, { emit }) {
    const {
      pedidoData,
      resetproductoData,
      totalBultos,
      productoData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
      deleteproductoData,
      isPermitSave,
    } = usePedidos()

    const onSave = () => {
      if (!isPermitSave.value) return
      store.dispatch('pedidos/addPedido', pedidoData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-pedido-sidebar-active', false)
        })
    }

    return {
      pedidoData,
      resetproductoData,
      totalBultos,
      productoData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
      deleteproductoData,
      isPermitSave,
      // Guardar nuevo
      onSave,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "~@core/scss/base/pages/app-email.scss";
#add-new-pedido-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
  form {
    overflow-x: hidden;
  }
  .txt-recortar {
    max-width: 90%;
  }
}
</style>
